// This file serves as the base file for variables.
// It should be imported and overridden by brands and must NOT be imported
// in the styles.scss file
// It should be imported in other scss files through the following line:
//    @import 'variables';
// This will import the brand overridden values if everything is set up
// correctly inside the angular.json file

$color-notifications: #ff0041 !default;
$color-basic: #efefef !default;
$color-site-background: #f4f4f4 !default;
$color-site-dark-background: #001041 !default;
$color-container-background: #fff !default;
$color-container-background-secondary: #eef2fa !default;
$color-container-background-alt: darken($color-container-background, 2%) !default; // #fafafa
$container-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3) !default;
$inner-card-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14) !default;
$light-background: rgba(255, 255, 255, 0.3) !default;
$selected-country-background: rgba(0, 16, 65, 0.24);

// Brand colors
$color-brand: #000 !default;
$color-brand-light: #000 !default;
$color-brand-dark: #000 !default;

$color-brand-title-secondary: rgba(0, 12, 45, 0.6) !default;
$color-brand-dark-with-opacity: rgba(0, 12, 45, 0.87) !default;
$color-brand-darker: #000 !default;
$color-brand-accent: #eee !default;
$color-brand-highlight: #000 !default;
$color-brand-accent-secondary: #000 !default;
$color-brand-live-highlight: #000 !default;

// Status colors
$color-success: #38be76 !default;
$color-dark-success: rgba(46, 125, 50, 1) !default;
$color-warning: #ffbc16 !default;
$color-danger: #e05247 !default;
$color-info: #01579b !default;

//  Message/Notification colors
$color-msg-success: $color-success !default;
$color-msg-warning: #ff9800 !default;
$color-msg-info: #2196f3 !default;
$color-msg-danger: #8a0019 !default;

// Borders
$color-border: #ededed !default;
$color-border-lighter: #d9d9d9 !default;
$color-border-light: rgba(255, 255, 255, 0.38) !default;
$color-border-light-alt: rgba(255, 255, 255, 0.12) !default;
$color-border-dark: rgba(0, 0, 0, 0.6) !default;
$color-border-dark-alt: rgba(0, 0, 0, 0.1) !default;
$color-border-alt: darken($color-border, 10%) !default; // #d4d4d4
$color-border-alt-2: rgba(255, 255, 255, 0.12) !default;
$border-radius-size: 4px !default;
$brand-dropdown-border-radius-size: 2px !default;
$border-radius-circle: 50% !default;

// Fonts
$font-size: 12px !default;
$font-family: 'Roboto Condensed', 'Arial Narrow', sans-serif !default;
$font-family-new: 'Roboto', 'Arial', sans-serif !default;

// Icons
$primary-light: rgba($color-site-dark-background, 0.5) !default;

// Responsive Dimensions
$mobile-small: 321px !default;
$mobile-medium: 374px !default;
$mobile-large: 460px !default;
$mobile-ex-large: 600px !default;
$tablet-size: 768px !default;

// Odd colors
$color-odd: #000 !default;
$color-odd-text: #fff !default;
$color-odd-secondary-text: #507dca !default;
$color-odd-selected: #eee !default;
$color-odd-text-selected: #000 !default;
$color-odd-spread-value-bg: rgba(192, 192, 192, 0.1) !default;
$color-boosted-odd-text-selected: #000;

// Text colors
$color-text-light: #fff !default;
$color-text-dark: #000 !default;
$color-text-dark-secondary: #333f67 !default;
$sports-list-text-color: rgba($color-text-light, 0.87) !default;

// Legend colors
$legend-in-progress: #ffbd00 !default;
$legend-in-evaluation: #ff9e47 !default;
$legend-won: #2da050 !default;
$legend-partially-won: #50e3c2 !default;
$legend-lost: #ff423d !default;
$legend-void: #757575 !default;
$legend-cashout: #bdb289 !default;
$legend-promotion: #86159d !default;
$legend-cancelled: #c6c83b !default;

// Loading
$loading-background-color: #fff !default;
$loading-bar-color: #000 !default;

// Logo
$login-logo-width: 10vh !default;
$login-logo-height: 75% !default;

// Sportsradar widget
$sr-widget-primary: #000 !default;
$sr-widget-home: #000 !default;
$sr-widget-away: #000 !default;
$sr-widget-base: #fff !default;
$sr-widget-text: #000 !default;

// Sports
$selection-bg-color: #fafafa !default;
$sport-chooser-selected-bg: #1f2f58 !default;
$sports-tab-selected-bg: #022854 !default;
$sport-link-selected-bg: #ebebeb !default;
$sport-dark-text: #000c2d !default;

// Football go result detail
$color-away-results: #dd122a !default;
$color-away-results: #1263dd !default;
$light-text: #000c2d99 !default;
$light-icon: #0010418a !default;

// Material Colors
$color-material-primary-50: #e3e5ed !default;
$color-material-primary-100: #b9bfd4 !default;
$color-material-primary-200: #8d96b6 !default;
$color-material-primary-300: #626e9a !default;

$color-material-secondary-text: #e3f1fd !default;

$color-material-grey-50: #fafafa !default;
$color-material-grey-100: #f5f5f5 !default;
$color-material-grey-200: #eee !default;
$color-material-grey-300: #e0e0e0 !default;
$color-material-grey-500: #9e9e9e !default;

$color-light-success-main: #2e7d32 !default;
$color-light-success-secondary: #123214 !default;
$color-light-success-190: #eaf2ea !default;

$color-light-error-main: #d32f2f !default;
$color-light-error-secondary: #541313 !default;
$color-light-error-190: #fae6e6 !default;

$color-light-info-main: #0288d1 !default;
$color-light-info-secondary: #013654 !default;
$color-light-info-190: #e6f3fa !default;

$color-light-warning-main: #ed6c02 !default;
$color-light-warning-secondary: #5f2b01 !default;
$color-light-warning-190: #fdf0e6 !default;

// Footer
$footer-border-color-light: rgba(255, 255, 255, 0.2) !default;

// Header
$header-button-color: #1affff !default;
$breadcrumb-no-header-height: 36px !default;

// Accumulator Bonus
$acc-bonus-container-background: #ffc400 !default;
$acc-bonus-container-text-color: $color-text-dark !default;
$acc-bonus-container-amount-color: $color-text-dark !default;
$acc-bonus-popup-background: linear-gradient(180deg, #001041 0%, #192854 100%) !default;
$acc-bonus-odds-value-popup-background: #f4fafe !default;
$acc-bonus-odds-value-popup-border-color: #2196f3 !default;
$acc-bonus-previous-step-color: #38446b !default;
$acc-bonus-current-step-color: #ffc400 !default;
$acc-bonus-upcoming-step-color: #b9bfd4 !default;

// Freebet Bonus
$freebet-container-background: #ffc400 !default;
$freebet-non-selected-box-shadow: rgba(0, 16, 65, 0.12) !default;
$freebet-divider-line: rgba(229, 236, 247) !default;
$freebet-text-color: rgba(0, 12, 45, 0.87) !default;
$freebet-criteria-background: #626e9a !default;
$freebet-button: #255dbd !default;

// Info Modal
$info-modal-header-background: linear-gradient(82.02deg, blue 0%, darkblue 71.86%) !default;
$info-modal-overlay: rgba(black, 0.64) !default;

$gradient-divider: linear-gradient(270deg, rgba(#001041, 0) 0%, rgba(#001041, 0.12) 50%, rgba(#001041, 0) 100%) !default;

// Virtuals
$virtuals-league-gradients: (
  // kings-league
  gradient-2660312: linear-gradient(129.53deg, #2662d9 8.39%, #121d73 92.63%),
  // kings-liga
  gradient-2660314: linear-gradient(130.23deg, #ffc400 8.46%, #ffc400 8.46%, #aa6f09 93.03%),
  // kings-italiano
  gradient-3250280: linear-gradient(129.9deg, #269dd9 7.69%, #084191 92.01%),
  // kings-bundliga
  gradient-16337569: linear-gradient(115.95deg, #e51c3e 16.21%, #930241 83.48%),
  // kings-euro-cup
  gradient-euro-cup:
    'linear-gradient(180deg, rgba(0, 16, 65, 0.00) 0%, rgba(0, 16, 65, 0.40) 100%), linear-gradient(259deg, #602D90 0%, #462169 100%)',
  gradient-afkon-cup:
    'linear-gradient(180deg, rgba(0, 16, 65, 0.00) 0%, rgba(0, 16, 65, 0.30) 100%), #00955F'
) !default;
$virtuals-league-icons: (
  icon-2660312: kings-league,
  icon-2660314: kings-liga,
  icon-3250280: kings-italiano,
  icon-16337569: kings-bundliga,
  icon-78777649: kings-bundliga,
  icon-72026820: kings-bundliga,
) !default;
$virtuals-card-bg-dark: #203674 !default;
$virtuals-card-container-bg-dark: #000b2d !default;
$virtuals-card-header-bg-dark: linear-gradient(180deg, #1a274d 0%, #001041 22.92%, #213775 100%) !default;
$virtuals-game-button-bg-dark: linear-gradient(180deg, #2a3d76 0%, #13255a 100%) !default;
$tournaments-common-image-background: url('/assets/images/backgrounds/kings-euro-banner-rays.webp');
$virtuals-game-button-gradients: (
  // reusing values from $virtuals-league-gradients to keep styles consistent
  // kings-league
  game-kings-league: map-get($virtuals-league-gradients, gradient-2660312),
  // kings-liga
  game-kings-liga: map-get($virtuals-league-gradients, gradient-2660314),
  // kings-italiano
  game-kings-italiano: map-get($virtuals-league-gradients, gradient-3250280),
  // kings-league
  game-kings-bundliga: map-get($virtuals-league-gradients, gradient-16337569),
  // kings-insta-live-league
  game-kings-insta-live-league:
    (url('/assets/images/backgrounds/instaleague-tile-chevron-bg.png') center right, map-get($virtuals-league-gradients, gradient-2660312)),
  // kings-insta-liga
  game-kings-insta-liga:
    (url('/assets/images/backgrounds/instaleague-tile-chevron-bg.png') center right, map-get($virtuals-league-gradients, gradient-2660314)),
  // kings-insta-italiano
  game-kings-insta-italiano:
    (url('/assets/images/backgrounds/instaleague-tile-chevron-bg.png') center right, map-get($virtuals-league-gradients, gradient-3250280)),
  // kings-insta-italiano
  game-kings-insta-bundliga:
    (url('/assets/images/backgrounds/instaleague-tile-chevron-bg.png') center right, map-get($virtuals-league-gradients, gradient-16337569)),
  // kings-euro-cup
  game-kings-euro-kup:
    ($tournaments-common-image-background center center, map-get($virtuals-league-gradients, gradient-euro-cup)),
  // kings-afkon-cup
  game-kings-afkon-kup:
    ($tournaments-common-image-background center center, map-get($virtuals-league-gradients, gradient-afkon-cup))
) !default;

$virtuals-jackpot-kingmaker-bg: #ff1aff !default;
$virtuals-jackpot-duke-bg: #1affff !default;
$virtuals-info-bubble-bg: #fff9 !default;
$virtuals-info-bubble-close-bg: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #0c1b4a !default;
$virtuals-info-bubble-color: #e3f1fd !default;
$virtuals-info-amount-bubble-color: #fff !default;
$virtuals-league-navbar-bg: $color-site-dark-background !default;
$virtuals-league-navbar-border: #1f2d58 !default;
$virtuals-slide-up-bg: $color-container-background !default;
$virtuals-slide-up-color: rgba(0, 12, 45, 0.87) !default;
$virtuals-slide-up-badge-bg: rgba(0, 16, 65, 0.08) !default;
$virtuals-slide-up-badge-color: rgba(0, 12, 45, 0.6) !default;
$virtuals-league-desktop-bg: #f6f9fc !default;
$virtuals-info-section-color: rgba(0, 12, 45, 0.87) !default;
$virtuals-coupon-error-border: #c62828 !default;
$virtuals-banner-bg-gradient-viewport: linear-gradient(180deg, #001041 0%, #213775 100%) !default;
$virtuals-info-sub-section-background: #fff !default;
$virtuals-lobby-divider: rgba(37, 93, 189, 0.12) !default;

// Banner
$gradient-background: linear-gradient(118.68deg, #001041 0.72%, #1f4f74 50.17%, #52b4c5 102.67%, #74f9fd 122.99%) !default;

// X-Sell
$x-sell-default-bg-color: $color-container-background !default;
$x-sell-default-icon-color: $color-brand !default;
$color-disabled-apply-button: rgba(0, 16, 65, 0.26) !default;
$color-disabled-apply-button-background: rgba(0, 16, 65, 0.12) !default;

// Breadcrumb Navigation
$breadcrumb-title-text-color: rgba(255, 255, 255, 0.6) !default;
$breadcrumb-title-text-active-color: rgba(255, 255, 255, 1) !default;
$breadcrumb-dropdown-background: #fff !default;
$breadcrumb-dropdown-item-text-color: rgba(0, 12, 45, 0.87) !default;
$breadcrumb-dropdown-item-hover-background: rgba(0, 16, 65, 0.04) !default;
$breadcrumb-fixed-desktop-height: 101px !default;
$breadcrumb-fixed-mobile-height: 139px !default;


// Number Selection
$number-selection-shadow: 0 1px 1px -2px rgba(0, 16, 65, 0.1), 0 2px 2px rgba(0, 16, 65, 0.07), 0 1px 2px rgba(0, 16, 65, 0.06) !default;

// Jackpots
$virtuals-jackpot-rotation-background: linear-gradient(180deg, $color-site-dark-background 16.49%, #255dbd 92.02%) !default;

$virtuals-jackpot-rotation-top-line-background: linear-gradient(
  90deg, rgba(67, 82, 135, 0) 0%, #435287 49.44%, rgba(67, 82, 135, 0) 100%)
  !default;

$virtuals-jackpot-kingmaker-currency-background: linear-gradient(
  to bottom, #ffe6b3 14.02%, #fff7e5 27.04%, #ffe6b3 40.58%, #b88014 100%)
  !default;

$virtuals-jackpot-duke-currency-background: linear-gradient(
  to bottom, #cdd3e4 14.02%, #eef0f6 27.04%, #cdd3e4 40.58%, #73798c 100%)
  !default;

$virtuals-jackpot-kingmaker-value-background: linear-gradient(
  180deg,#ffe6b3 26.09%,#fff7e5 35.39%,#ffe6b3 45.06%,#b88014 87.49%)
  !default;

$virtuals-jackpot-duke-value-background: linear-gradient(
  to bottom, #e5e5e5 22%, #fff 36%, #ececec 45%, #fff 56%, #a3a3a3 82%, #898a8c 100%)
  !default;

$virtuals-jackpot-name-color: #fff !default;
$virtuals-jackpot-message-color: #e3e5ed !default;


// Predefined bets
$color-predefined-bet-title: #194184 !default;
